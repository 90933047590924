// Colors
$MainBlue: #3b4863;
$MainYellow: #ffa929;
$MainOrange: #ed8c00;
$MainBlack: #191b1e;
$SecondBlack: #44474f;
$MainLightBlue: #0288d1;
$DarkGreen: #05a358;
$MainGreen: #71b051;
$OtherGreen: #44ba82;
$Turquesa: #46d1ca;
$MainRed: #d81159;
$MainPurple: #50249f;
$LightPuprle: #9860c3;
$MainGrey: #50545f;
$Grey80: #7e7e7e;
$Grey60: #9e9e9e;
$Grey35: #c7c7c7;
$Grey25: #d7d7d7;
$Grey10: #efefef;
$Grey05: #f7f7f7;
$Yellow80: #ffba54;
$Yellow60: #ffcb7f;
$Yellow35: #ffe1b4;
$Yellow25: #ffe9c9;
$Yellow10: #fff6ea;
$Yellow05: #fffbf4;
$ErrorMessage: #f0a14e;
$BorderColor: #a0a1b0;
$BackgroundColor: #e9eaeb;
$BackgroundColorTwo: #f5f6f7;
$BackgroundColorThree: #f8f8f8;
$BackgroundColorBlue: #c3daf3;
$BackgroundColorBlueTwo: #80b9f0;
$UnderlineBlue: #b7d3f0;
$highlightBlue: #0073e0;
$AnchorLightBlue: #0069db;
$LightBlue: #c3daf3;
$LightGrey: #d3d4d7;
$AnchorButtonGrey: #ebecef;
$BackgroundDarkBlue: #085fb0;
$BackgroundBlue: #4096e8;
$BackgroundDarkBlue: #085fb0;
$BackgroundLightBlue: #dbe9f8;
$BackgroundBlueSky: #e5f1fc;
$BackgroundOrange: #f0a14e;
$BackgroundGrey: #e5e5e5;
$BackgroundGrey2: #c4c4c4;
$ButtonOrange: #ed8c00;
$StableGreyLight: #a8aaaf;
$StableGreyLight02: #7c7f87;
$GreyBackgroundColor: #ced1d8;
$AssuranceBlueLight: #f3f8fc;
$ZO-Light03: #fae0c4;
$ZO-Light02: #fecc97;
$InnovationFushia: #d61d98;
$OptimismYellow: #fff8eb;
$ZealOrangeBackground: #fef8f1;

// New Design System Colors
$AssuranceBlue: #0073e0;
$AssuranceBlueLight02: #80b9f0;
$AssuranceBlueLight05: #dbe9f8;
$AssuranceBlueLight06: #f3f8fc;
$AssuranceBlueDark01: #085fb0;
$OffBlack: #191b1e;
$PowerRed: #e02819;
$SGLight01: #7c7f87;
$SGLight03: #d3d4d7;
$SGLight04: #e9eaeb;
$SGLight05: #f8f8f8;
$StableGrey: #50545f;
$PGLight02: #82d1ac;
$SGDark01: #44474f;
$ZOLight04: #fef8f1;
$ZOLight03: #fae0c4;
$ZOLight02: #fecc97;
$ZOLight01: #f0a14e;
$ZealOrange: #ed8c00;
$PlanetGreenLight01: #44ba82;
$PlanetGreenLight04: #e1f4eb;
$PlanetGreen: #05a358;
$StableGreyLight03: #d3d4d7;
$ZO-Light03: #fae0c4;
$ZO-Light02: #fecc97;
$OY-Light03: #fff2d6;

// Responsive width
$mobile: 360px;
$smallWindow: 576px;
$tablet: 744px;
$mediumWindow: 868px;
$normalWindow: 1024px;
$desktop: 1130px;
$largeWindow: 1180px;
$superLargeWindow: 1440px;

// Responsive width v2
$tablet: 744px;
$desktop: 1130px;

// Height
$HeaderHeight: 90px;
$HeaderHeightResponsive: 60px;
$FooterHeightDesktop: 630px;
$OverHeaderHeight: 0px;

//Font weight
$font-weights: (
  'regular': 400,
  'medium': 500,
  'bold': 600,
  'black': 700,
);

// Font sizes mobile
$font-sizes-mobile: (
  'description': 16px,
  'mainDescription': 21px,
  'title': 34px,
  'mainTitle': 40px,
);

// Font sizes Desktop
$font-sizes: (
  'description': 16px,
  'mainDescription': 30px,
  'title': 40px,
  'mainTitle': 55px,
  'dateTitle': 93px,
);

// Animations speed
$tabAnimation: 300ms;
