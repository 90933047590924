@import './variables.scss';

.layout {
  max-width: $largeWindow;
  width: 100%;
  margin: 0 auto;
  flex: 1;
  height: 100%;
  padding: 0px 30px;
  @media (max-width: $tablet) {
    padding: 0px 20px;
  }
}
