@import "~bootstrap";
@import "./assets/fonts/PulpDisplay/stylesheet.css";

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: unset;
  }
}

:focus-visible {
  outline: inherit;
}

@keyframes fade-in-top {
  from {
    opacity: 0;
    transform: translate(0, 40px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@keyframes focus-appointment {
  from {
    border-color: #e3dbd1;
    background-color: #fffde8;
  }
}

p {
  margin: 0;
}

.btn-secondary:focus,
.btn-secondary:active:focus,
.btn-outline-secondary:focus,
.btn-outline-secondary:active:focus {
  box-shadow: none;
}

.btn-outline-secondary:hover {
  color: #6c757d;
  background-color: transparent;
  border-color: #6c757d;
}

nav.navbar a {
  color: inherit;
}

.appointment {
  border: solid 1px #dee2e6;
  background-color: var(--bs-light);

  &.focused {
    animation: focus-appointment 2.5s ease-in;
  }
}

@keyframes copy-feedback {
  0% {
    opacity: 1;
    transform: translate(0, 20px);
  }
  100% {
    opacity: 0;
    transform: translate(0, 0);
  }
}

.copy-feedback {
  position: absolute;
  color: red;
  font-size: 20px;
  margin-left: -40px;
  margin-top: -50px;
  opacity: 0;
  &.active {
    animation: copy-feedback 0.6s linear;
  }
}
