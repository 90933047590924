@import './variables.scss';

.prendreRendezVous {
  @media (min-width: $mediumWindow) {
    padding-top: 81px;
  }

  * + section {
    margin-top: 50px;
  }

  &__main {
    display: flex;
    flex-direction: column;

    @media (min-width: $mediumWindow) {
      gap: 143px;
      flex-direction: row;
    }
  }

  &__section_container {
    .title {
      display: flex;
      align-items: center;
      gap: 0.75rem;

      margin-bottom: 28px;

      > h2 {
        font-family: 'PulpDisplay';
        font-weight: bold;
        font-size: 1.125rem;
        margin-bottom: 0;
        margin-top: 2px;

        @media (min-width: $mediumWindow) {
          font-size: 1.5rem;
        }
      }
      > img {
        width: 24px;
        height: 24px;

        @media (min-width: $mediumWindow) {
          width: 32px;
          height: 32px;
        }

        filter: brightness(0) saturate(100%) invert(23%) sepia(88%)
          saturate(2392%) hue-rotate(194deg) brightness(104%) contrast(107%);
      }
    }

    form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 30px;

      @media (min-width: $mediumWindow) {
        gap: 120px;
        flex-direction: row;

        align-items: flex-start;
      }

      .main_information {
        flex: 1;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 32px;
        width: 100%;

        > div {
          width: 100%;
        }

        @media (min-width: $mediumWindow) {
          display: grid;
          max-width: 456px;
          gap: 24px;
          grid-template-columns: repeat(2, 1fr);

          .firstname {
            grid-column: 1/1;
          }

          .lastname {
            grid-column: 2/2;
          }

          .email {
            grid-column: 1/3;
            grid-row: 2;
          }

          .phone {
            grid-column: 1/3;
            grid-row: 3;
          }
          .address {
            grid-column: 1/3;
            grid-row: 4;
          }
        }
      }

      .notes {
        width: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 30px;

        align-items: flex-start;
        > div {
          max-width: 480px;
        }
      }

      .membershipCode {
        width: 100%;
      }

      .checkBox {
        display: flex;
        gap: 10px;

        > svg {
          flex: 0.08;
        }

        > p {
          flex: 1.95;
          color: $MainGrey;
          font-size: 0.875rem;
          line-height: 1.1394rem;
          font-size: 0.75rem;
          margin-top: 5px;

          @media (min-width: $mediumWindow) {
            font-size: 0.875rem;
          }
        }
      }
    }

    > p {
      margin-bottom: 44px;
      font-size: 1.125rem;
      color: $MainGrey;
      @media (min-width: $mediumWindow) {
        font-size: 1.5rem;
      }
    }
  }

  &__LoadingContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
  }

  &__button_container {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    align-items: center;

    @media (min-width: $mediumWindow) {
      margin-top: 64px;
    }
  }

  &__Calendar {
    @media (max-width: $mediumWindow) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  &__calendarDesktop {
    display: none;
    @media (min-width: $smallWindow) {
      display: block;
    }
  }
  &__calendarMobile {
    display: block;
    @media (min-width: $smallWindow) {
      display: none;
    }
  }

  &__errorMessage {
    font-family: 'PulpDisplay';
  }
}
.displayInMobile {
  display: block;
  @media (min-width: $mediumWindow) {
    display: none !important;
  }
}
